import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './styles/themes';
import Header from 'pages/Header';
import GlobalStyle from './styles/global-styles';
import Content from 'pages/Content';
import rootReducer from './modules';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Loading from 'components/Loading';

const store = createStore(rootReducer);

function App() {
	const [theme, setTheme] = useState('dark');
	const toggleTheme = () => {
		if (theme === 'light') {
			setTheme('dark');
		} else {
			setTheme('light');
		}
	};

	return (
		<Router>
			<Routes>
				<Route
					path='CV_KOR'
					Component={() => {
						window.location.href =
							'https://docs.google.com/document/d/1fIwJFuv6db40-ieUyz6DI7zbNRELflMsrsb6fudMOS0/edit?usp=sharing';
						return null;
					}}
				/>
				<Route
					path='CV_ENG'
					Component={() => {
						window.location.href =
							'https://docs.google.com/document/d/1eC_V9gv-k7WVPs0qgtey_obxayzaw5VQmzCa0ZnWSHQ/edit?usp=sharing';
						return null;
					}}
				/>
				<Route
					path='*'
					Component={() => (
						<Provider store={store}>
							<ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
								<Wrap>
									<Loading />
									<GlobalStyle />
									<Header theme={theme} setTheme={toggleTheme} />
									<Content />
								</Wrap>
							</ThemeProvider>
						</Provider>
					)}
				/>
			</Routes>
		</Router>
	);
}

const Wrap = styled.div`
	height: auto;
`;

export default App;
