import styled, { css, keyframes } from 'styled-components';

import HobbyPng1 from 'imgs/hobby/png/hobby1.png';
import HobbyPng2 from 'imgs/hobby/png/hobby2.png';

import { useSelector } from 'react-redux';
import { RootState } from 'modules';
import { useState } from 'react';
function AboutMe() {
	const nowsection = useSelector(
		(state: RootState) => state.sectionHandle.nowsection
	);
	const [flash, setFlash] = useState<boolean>(true);
	const flashHandler = () => {
		setFlash((flash) => !flash);
	};
	return (
		<AboutMeDIV>
			<Title>About Me</Title>
			<FlashEffect nowsection={nowsection} className={`flash${flash}`} />
			<FlashBtn onClick={flashHandler}>
				{flash ? '플래시끄기' : '플래시켜기'}
			</FlashBtn>
			<ImgArea>
				<HobbyImg1 src={HobbyPng1} nowsection={nowsection} />
				<HobbyImg2 src={HobbyPng2} nowsection={nowsection} />
			</ImgArea>
			<MentArea nowsection={nowsection}>
				<MentTitle>만나서 반갑습니다.</MentTitle>
				<MentSub>개발자로 활동중인 황주현 입니다.</MentSub>
				<MentSub>
					주도적으로 업무하고, 제 역량을 기여할 수 있는 환경을 좋아합니다.
				</MentSub>
				<MentSub>
					사용자를 넘어서 개발자에게도 좋은 경험을 전달하기 위해 고민합니다.
				</MentSub>
				<MentSub>
					여가시간에는 사진촬영, 피아노, 수영을 주로 하고 운전을 좋아합니다.
				</MentSub>
				<br />
				<Info>학력사항 : 숭실대학교 소프트웨어학부</Info>
			</MentArea>
		</AboutMeDIV>
	);
}

const ImgArea = styled.div`
	position: relative;
	display: flex;
	width: 40%;
`;

const AboutMeDIV = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
	@media only screen and (max-width: 880px) {
		flex-direction: column;
		${ImgArea} {
			display: none;
		}
	}
`;

const imgkeyback = keyframes`
	from {opacity:1;}
	to{opacity:0}
`;

const imgkey2 = keyframes`
	from {opacity:0;
	transform: translateY(-40.5%) rotate(-10deg);
transform-origin : 0 100%;}
	to{opacity:1;
	transform: translateY(-40.5%) rotate(0);
	transform-origin : 0 100%;}
`;

const MentArea = styled.div<{ nowsection: number }>`
	display: flex;
	flex-direction: column;
	width: 50%;
	opacity: 0;
	${(props) =>
		props.nowsection === 3
			? css`
					transition: opacity 0.1s 1s;
					opacity: 1;
			  `
			: css`
					transition: opacity 1s;
					opacity: 0;
			  `};
	@media only screen and (max-width: 880px) {
		width: 80%;
	}
	word-break: keep-all;
`;

const Title = styled.h1`
	font-size: 6rem;
	font-weight: 400;
	position: absolute;
	margin: 0;
	left: 3rem;
	top: 3rem;
	color: ${({ theme }: { theme: any }) => theme.titleFontColor};
`;

const flash = keyframes`
	0%{
		visibility:hidden;
		opacity:0;
	} 1%{
		visibility:visible;
		opacity:0;
	} 49%{
		opacity:1;
	} 100% {
		opacity:0;
		visibility:hidden;
	}
`;

const FlashBtn = styled.button`
	position: absolute;
	cursor: pointer;
	border: none;
	border-radius: 1rem;
	font-size: 2rem;
	font-weight: 500;
	left: 10rem;
	top: 20%;
	z-index: 2;
	color: ${({ theme }: { theme: any }) => theme.aboutmeFlashColor};
	background: ${({ theme }: { theme: any }) => theme.aboutmeFlashBgColor};
`;

const HobbyImg1 = styled.img<{ nowsection: number }>`
	position: absolute;
	left: 10rem;
	top: 50%;
	transform: translateY(-50%);
	width: 70%;
	min-width: 272.69px;
	z-index: 1;
	filter: drop-shadow(5px 5px 5px #00000070);
	opacity: 0;
	${(props) =>
		props.nowsection === 3
			? css`
					transition: opacity 0.5s 0.3s;
					opacity: 1;
			  `
			: css`
					transition: opacity 1s;
					opacity: 0;
			  `};
`;

const HobbyImg2 = styled.img<{ nowsection: number }>`
	position: absolute;
	left: 18rem;
	top: 50%;
	transform: translateY(-40.5%);
	width: 67%;
	min-width: 245.42px;
	z-index: 0;
	filter: drop-shadow(5px 5px 5px #00000070);
	opacity: 0;
	${(props) =>
		props.nowsection === 3
			? css`
					animation: ${imgkey2} 0.4s 0.5s forwards ease-in-out;
			  `
			: css`
					animation: ${imgkeyback} 1s forwards;
			  `};
`;

const FlashEffect = styled.div<{ nowsection: number }>`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: white;
	z-index: 5;
	opacity: 0;

	${(props) =>
		props.nowsection === 3
			? css`
					animation: ${flash} 0.3s 0.9s ease-in-out forwards;
			  `
			: css``}
	&.flashfalse {
		visibility: hidden !important;
	}
`;

const MentTitle = styled.h2`
	font-size: 5.5rem;
	font-weight: 500;
	color: ${({ theme }: { theme: any }) => theme.aboutmeMentTitleColor};
`;

const MentSub = styled.h3`
	font-size: 3rem;
	font-weight: 200;
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: ${({ theme }: { theme: any }) => theme.aboutmeMentSubColor};
	b {
		color: ${({ theme }: { theme: any }) => theme.aboutmeMentSubColor};
	}
`;

const Info = styled.h3`
	font-size: 3rem;
	font-weight: 500;
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: ${({ theme }: { theme: any }) => theme.aboutmeInfoColor};
`;

export default AboutMe;
