import { List, Title, RefImg, RefComment, UseSkill } from './styled';

import Imgsrc from 'imgs/reference/png/One.png';

function One({ isShow }: { isShow: boolean }) {
	return (
		<List className={isShow ? '' : 'notShow'}>
			<Title>Reference(정리중)</Title>
			<RefImg src={isShow ? Imgsrc : ''} />
			<RefComment>
				Reference를 정리하고 있어요.
				<br />
				조금만 기다려 주세요 :)
			</RefComment>
		</List>
	);
}

export default One;
